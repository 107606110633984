import { useRef, useState } from "react";
import axios from "axios";
import { useRouter } from "next/router";
import { getCookie } from "cookies-next";
import { GetServerSideProps } from "next";
import ReCAPTCHA from "react-google-recaptcha";

import { Button } from "@material-tailwind/react";
import Link from "next/link";
import GenericHead from "@/src/components/Head/GenericHead";
import { sendCaptchaVerification } from "@/src/utils/helpers";

const Login = () => {
  const router = useRouter();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [isVerified, setIsverified] = useState<boolean>(true);
  async function handleCaptchaSubmission(token: string | null) {
    // Server function to verify captcha
    setIsverified(true);

    await sendCaptchaVerification(token || "")
      .then((resp) => {
        console.log("THE RESP: ", resp);
        setIsverified(resp);
      })
      .catch((e) => {
        console.log("E: ", e);
        setIsverified(false);
        setIsverified(true);
      });
  }
  const [loginFields, setLoginFields] = useState({
    username: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const onFormSubmit = (event: any) => {
    event.preventDefault();
    setErrorMessage(null);

    // Validation and Sanitization.

    setLoading(true);
    return axios({
      data: {
        username: loginFields?.username ?? "",
        password: loginFields?.password ?? "",
      },
      method: "post",
      url: "/api/login",
    })
      .then((data) => {
        const { success } = data?.data ?? {};

        // If its a preview request

        if (!success) {
          setLoading(false);
          //@ts-ignore
          setErrorMessage("Couldn't login!");
        } else {
          setTimeout(() => {
            setLoading(false);
            router.push("/account/myaccount");
          }, 200);
        }
      })
      .catch((e) => {
        setLoading(false);
        setErrorMessage("Couldn't Login!");
        console.log("Error: ", e);
        return false;
      });
  };

  /**
   * Sets client side error.
   *
   * Sets error data to result received from our client side validation function,
   * and statusbar to true so that its visible to show the error.
   *
   * @param {Object} validationResult Validation Data result.
   */

  const handleOnChange = (event: any) => {
    setLoginFields({ ...loginFields, [event.target.name]: event.target.value });
  };

  const { username, password } = loginFields;
  return (
    <>
      <GenericHead />

      <div className="login-form rounded-xl md:ml-auto md:mt-12 md:w-5/12 px-4 md:px-0 m-auto my-6">
        <div
          className={
            "flex bg-scpb-dark-blue p-2 rounded-t-lg text-white items-start align-center w-full justify-center"
          }
        >
          <h4 className="text-white text-3xl title-font block">Login</h4>
        </div>
        <div className="bg-gray-100 p-8   shadow-xl">
          <form onSubmit={onFormSubmit} className="mb-4 flex flex-col">
            <div className="flex flex-col gap-2">
              <label className="leading-7 flex flex-col gap-2 text-sm text-gray-600">
                Username:
                <input
                  type="text"
                  className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  name="username"
                  value={username}
                  onChange={handleOnChange}
                  autoComplete="username"
                />
              </label>

              <label className="leading-7 flex flex-col gap-2 text-sm text-gray-600">
                Password:
                <input
                  type="password"
                  className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  name="password"
                  value={password}
                  onChange={handleOnChange}
                  autoComplete="current-password"
                />
              </label>
            </div>
            <div className={"py-4 flex w-full"}>
              {/* <ReCAPTCHA
                sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ""}
                ref={recaptchaRef}
                onChange={handleCaptchaSubmission}
              /> */}
            </div>
            <p className="py-4 text-red-400">{errorMessage}</p>
            {errorMessage && (
              <Link href="account/recoverpassword">
                Having Trouble? Reset Password
              </Link>
            )}
            <Button
              className="text-white bg-scpb-light-blue border-0 py-2 px-8 focus:outline-none hover:bg-scpb-dark-blue rounded text-lg"
              type="submit"
              disabled={!isVerified}
            >
              {loading ? "Loading..." : "Login"}
            </Button>
          </form>
          <Link href="/register" className="cursor-pointer">
            Don&apos;t have an account?{" "}
            <span className="underline">Register</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const userData = getCookie("userData", context);

  return { props: {} };
};

export default Login;
